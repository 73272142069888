import {fromPairs, kebabCase} from 'lodash';

import {enumKeys} from '@messenger/uikit/src/Utils/enumKeys';

export enum IconSize {
	inherit,
	auto,
	nano,
	micro,
	tiny,
	small,
	default,
	medium,
	large,
	xlarge,
	xxlarge,
}

export enum IconColor {
	auto,
	grey,
	lightgrey,
	cherry,
	white,
	blue,
	orange,
	red,
	green,
	black,
}

export enum IconType {
	//theme
	archive,
	archiveMove,
	archiveRemove,
	attach,
	avs18,
	noAvs18,
	softChat,
	softChatOff,
	videoChat,
	discount,
	emoji,
	toy,
	toyOff,
	toyVibrating,
	ticket,
	macro,
	msn,
	msnEmpty,
	micAlt,
	nightMode,
	personOff,
	send,
	voyeurFull,
	voyeurFullOff,
	voyeurLine,
	wait,
	vx,
	//mui
	dollarSymbol,
	euroSymbol,
	favorite,
	favoriteBorder,
	volumeOff,
	volumeDown,
	volumeUp,
	volumeMute,
	warning,
	notes,
	star,
	person,
	graphicEq,
	panorama,
	panoramaOutlined,
	stars,
	ondemandVideo,
	shoppingCart,
	confirmationNumber,
	confirmationNumberOutlined,
	chevronRight,
	chevronLeft,
	moreHoriz,
	moreVert,
	menu,
	mic,
	micOff,
	micNone,
	phone,
	phoneLocked,
	phoneDisabled,
	phoneInTalk,
	callEnd,
	peopleOutline,
	group,
	hourglassEmpty,
	openInNew,
	help,
	helpOutline,
	autorenew,
	error,
	errorOutline,
	search,
	addCircleOutline,
	liveTv,
	liveHelp,
	removeCircle,
	cancelOutlined,
	cancelRounded,
	check,
	checkCircleOutline,
	checkCircleRounded,
	localOffer,
	percent,
	meetingRoom,
	visibility,
	visibilityOff,
	aspectRatio,
	face,
	textFields,
	hearing,
	block,
	expandMore,
	expandLess,
	arrowForward,
	close,
	folderOutlined,
	reportProblem,
	playArrowRounded,
	playCircleOutline,
	pauseCircleOutline,
	drafts,
	email,
	desktopMac,
	highlightOff,
	deleteOutlined,
	keyboardArrowLeft,
	keyboardArrowDown,
	pause,
	lock,
	lockOpen,
	infoOutlined,
	info,
	radioButtonUncheckedRounded,
	addPhotoAlternate,
	arrowDownward,
	doneAll,
	refresh,
	settings,
	videoCam,
	storage,
	equalizer,
	home,
	syncAlt,
	filterNone,
	cached,
	edit,
	add,
	insertDriveFile,
	addAPhoto,
	redeem,
	tune,
	photoCamera,
	contentCopy,
	pictureInPicture,
	launch,
	create,
	time,
	flipCamera,
	reply,
	centerFocusWeak,
	schedule,
	sync,
	powerSettingsNew,
	notifications,
	networkCheck,
	stopRounded,
	//mdi
	pin,
	pinOff,
	crown,
}

export enum IconSource {
	theme,
	mui,
	mdi,
}

export const IconNames = fromPairs(enumKeys(IconType).map((icon) => [icon, kebabCase(icon)]));
