import {createSlice, EntityState} from '@reduxjs/toolkit';
import {MediaFile, EnumMediaType, UploadedMediaDirection, ChannelMediaLinkState} from 'cmd-control-client-lib';
import _ from 'lodash';

import EnumStore from '@messenger/core/src/BusinessLogic/EnumStore';
import {BULK} from '@messenger/core/src/BusinessLogic/Constants/ChatTypes';

import {mediaServerToClientActions} from './Actions/mediaServerToClientActions';
import {MEDIA_HISTORY_SUBJECT_ID, mediaClientToServerActions} from './Actions/mediaClientToServerActions';
import {mediaClientOnlyActions} from './Actions/mediaClientOnlyActions';
import {mediaEntityAdapter} from './mediaEntityAdapter';

export const initialMediaState: TMediaState = mediaEntityAdapter.getInitialState({
	uploadUrl: '',
	mediaHistoryStatuses: {},
});

export const mediaSlice = createSlice({
	name: EnumStore.MEDIA,
	initialState: initialMediaState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(mediaClientOnlyActions.setMediaUploadUrl, (state, action) => {
			state.uploadUrl = action.payload;
		});
		builder.addCase(mediaClientOnlyActions.upsertMany, mediaEntityAdapter.upsertMany);
		builder.addCase(mediaClientOnlyActions.addMany, mediaEntityAdapter.addMany);
		builder.addCase(mediaClientToServerActions.requestMediaHistory, (state, {payload}) => {
			const subjectId = payload[MEDIA_HISTORY_SUBJECT_ID];

			if (subjectId) {
				const status = state.mediaHistoryStatuses[subjectId] || {};

				if (!status.isLoading) {
					state.mediaHistoryStatuses[subjectId] = {...status, isLoading: true};
				}
			}
		});
		builder.addCase(mediaServerToClientActions.mediaHistoryReceived, (state, {meta: {params, commands}}) => {
			const mediaHistorySubjectId: string = _.get(params, MEDIA_HISTORY_SUBJECT_ID);

			if (mediaHistorySubjectId) {
				const limit = params.limit ? _.parseInt(String(params.limit), 10) : 0;
				const skip = params.skip ? _.parseInt(String(params.skip), 10) : 0;

				state.mediaHistoryStatuses = {
					...state.mediaHistoryStatuses,
					[mediaHistorySubjectId]: {
						skip: skip + limit,
						endReached: _.size(commands) < limit,
						isReady: true,
						isLoading: false,
					},
				};
			}
		});
	},
});

export type TMediaState = EntityState<MediaFile> & {
	uploadUrl: string;
	mediaHistoryStatuses: TMediaHistoryStatuses;
};

export type TMediaHistoryStatuses = Record<
	string,
	{
		skip?: number;
		endReached?: boolean;
		isLoading?: boolean;
		isReady?: boolean;
	}
>;

export type TMediaHistoryFilters = {
	channelId?: string;
	linkState?: ChannelMediaLinkState[];
	mediaType?: EnumMediaType[];
	direction?: UploadedMediaDirection;
};

export const getMediaHistorySubjectId = ({channelId, linkState, mediaType, direction}: TMediaHistoryFilters) =>
	!channelId && !linkState && !mediaType && !direction
		? BULK
		: `${channelId}-${_.chain(linkState).sort().join('-')}-${_.chain(mediaType).sort().join('-')}-${direction}`;
