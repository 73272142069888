import {VideoSdkConfig, EnumMobileVideoCallState} from 'cmd-control-client-lib';

import {ClientOnlyActions} from '@messenger/core/src/Actions/ActionCreator';
import EnumStore from '@messenger/core/src/BusinessLogic/EnumStore';

enum VideoCallAction {
	START_CALL = 'START_CALL',
	RESET_STORE = 'RESET_STORE',
	CALL_STARTED = 'CALL_STARTED',
	UPDATE_CALL = 'UPDATE_CALL',
	ACCEPT_CALL = 'ACCEPT_CALL',
	SET_CALL_ID = 'SET_CALL_ID',
	SET_DESCRIPTION_VISIBILITY = 'SET_DESCRIPTION_VISIBILITY',
	SET_AVAILABILITY_STATUS_SWITCHED = 'SET_AVAILABILITY_STATUS_SWITCHED',
	SET_AVAILABILITY_STATUS = 'SET_AVAILABILITY_STATUS',
	SET_HAS_INCOMING_CALL = 'SET_HAS_INCOMING_CALL',
	NAVIGATE_TO_VIDEO_CALL = 'NAVIGATE_TO_VIDEO_CALL',
	SET_HAS_ANSWERING_CALL = 'SET_HAS_ANSWERING_CALL',
	SET_START_TIMESTAMP = 'SET_START_TIMESTAMP',
}

class VideoCallClientOnlyActions extends ClientOnlyActions<EnumStore.VIDEO_CALL> {
	readonly scope = EnumStore.VIDEO_CALL;

	startCall = this.createAction(VideoCallAction.START_CALL, this.getPrepareAction<{channelId: string}>());

	setCallId = this.createAction(VideoCallAction.SET_CALL_ID, this.getPrepareAction<{callId: string}>());

	setDescriptionVisibility = this.createAction(
		VideoCallAction.SET_DESCRIPTION_VISIBILITY,
		this.getPrepareAction<boolean>(),
	);

	acceptCall = this.createAction(VideoCallAction.ACCEPT_CALL, this.getPrepareAction<string | undefined>());

	callStarted = this.createAction(VideoCallAction.CALL_STARTED, this.getPrepareAction<TVideoCallConfig>());

	resetStore = this.createAction(VideoCallAction.RESET_STORE);

	updateCall = this.createAction(
		VideoCallAction.UPDATE_CALL,
		this.getPrepareAction<{state: EnumMobileVideoCallState}>(),
	);

	setAvailabilityStatus = this.createAction(VideoCallAction.SET_AVAILABILITY_STATUS, this.getPrepareAction<boolean>());

	setAvailabilityStatusSwitched = this.createAction(VideoCallAction.SET_AVAILABILITY_STATUS_SWITCHED);

	setHasIncomingCall = this.createAction(VideoCallAction.SET_HAS_INCOMING_CALL, this.getPrepareAction<boolean>());

	setHasAnsweringCall = this.createAction(VideoCallAction.SET_HAS_ANSWERING_CALL, this.getPrepareAction<boolean>());

	navigateToVideoCall = this.createAction(VideoCallAction.NAVIGATE_TO_VIDEO_CALL);

	setStartTimestamp = this.createAction(VideoCallAction.SET_START_TIMESTAMP, this.getPrepareAction<number>());
}

export const videoCallClientOnlyActions = new VideoCallClientOnlyActions();

export type TVideoCallConfig = {
	videoSdkConfig: VideoSdkConfig;
	callId: string;
	isSoftChat: boolean;
	isVoIpCall: boolean;
	participantName: string;
	participantPhoto?: string;
	participantId?: string;
};

export type TVideoCallConfigWithTimestamp = TVideoCallConfig & {timestamp: number};
