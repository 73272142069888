import {ChatFlags, CMDC_CEXIT, CMDC_CINIT, CMDC_UPDATE_CHAT} from 'cmd-control-client-lib';
import _ from 'lodash';

import {ClientOnlyActions, TMultiEntityActionMeta} from '@messenger/core/src/Actions/ActionCreator';
import EnumStore from '@messenger/core/src/BusinessLogic/EnumStore';
import IChat from '@messenger/core/src/Redux/Chats/Model';

import prepareChatUpdatePayload from './prepareChatUpdatePayload';

enum EnumClientActions {
	RESET_STORE = 'RESET_STORE',
	RESET_SELECTED = 'RESET_SELECTED',
	SELECT_CHAT = 'SELECT_CHAT',
	UNMUTE_TEXT = 'UNMUTE_TEXT',
	MARK_AS_PRIVATE = 'MARK_AS_PRIVATE',
	FORCE_FLAGS = 'FORCE_FLAGS',
	UPSERT_MANY = 'UPSERT_MANY',
	PRUNE_EXITED_ADMINS = 'PRUNE_ADMINS',
	REMOVE_MANY = 'REMOVE_MANY',
	MARK_UNSEEN = 'MARK_UNSEEN',
	MARK_SEEN = 'MARK_SEEN',
	SET_VISIBLE_CHATS_LIMIT = 'SET_VISIBLE_CHATS_LIMIT',
	ADD_TYPING_CHAT = 'ADD_TYPING_CHAT',
	REMOVE_TYPING_CHAT = 'REMOVE_TYPING_CHAT',
	SET_IS_GROUP_CHAT_EXPANDED = 'SET_IS_GROUP_CHAT_EXPANDED',
	UPDATE_CHAT_VIDEO_TIMES_MANY = 'UPDATE_CHAT_VIDEO_TIMES_MANY',
}

class ChatsClientOnlyActions extends ClientOnlyActions<EnumStore.CHATS> {
	readonly scope = EnumStore.CHATS;

	resetStore = this.createAction(EnumClientActions.RESET_STORE);

	selectChat = this.createAction(EnumClientActions.SELECT_CHAT, this.getPrepareAction<string>());

	resetSelected = this.createAction(EnumClientActions.RESET_SELECTED, this.getPrepareAction<boolean | undefined>());

	unmuteText = this.createAction(EnumClientActions.UNMUTE_TEXT, prepareChatUpdatePayload);

	markAsPrivate = this.createAction(EnumClientActions.MARK_AS_PRIVATE, prepareChatUpdatePayload);

	forceFlags = this.createAction(EnumClientActions.FORCE_FLAGS, (payload: TForceFlagsActionParams) =>
		prepareChatUpdatePayload(payload),
	);

	removeMany = this.createAction(EnumClientActions.REMOVE_MANY, this.getPrepareAction<string[]>());

	upsertMany = this.createAction(
		EnumClientActions.UPSERT_MANY,
		(
			payload: TBatchUpsertChatCommand[],
			meta?: TMultiEntityActionMeta<CMDC_CINIT | CMDC_UPDATE_CHAT | CMDC_CEXIT>,
		) => ({
			payload: payload as IChat[],
			meta,
		}),
	);

	updateChatVideoTimesMany = this.createAction(
		EnumClientActions.UPDATE_CHAT_VIDEO_TIMES_MANY,
		(payload: TBatchUpsertChatCommand[]) => ({
			payload: _.reduce(
				payload,
				(acc: Record<string, number>, {chatID, videoTime}) => {
					if (videoTime) {
						const videoTimeInt = parseInt(videoTime, 10);

						if (!isNaN(videoTimeInt)) {
							acc[chatID] = videoTimeInt;
						}
					}

					return acc;
				},
				{},
			),
		}),
	);

	markUnseen = this.createAction(EnumClientActions.MARK_UNSEEN, this.getPrepareAction<string[]>());

	markSeen = this.createAction(EnumClientActions.MARK_SEEN, this.getPrepareAction<string[]>());

	pruneExitedAdminChats = this.createAction(EnumClientActions.PRUNE_EXITED_ADMINS, this.getPrepareAction<string[]>());

	setVisibleChatsLimit = this.createAction(EnumClientActions.SET_VISIBLE_CHATS_LIMIT, this.getPrepareAction<number>());

	addTypingChat = this.createAction(EnumClientActions.ADD_TYPING_CHAT, this.getPrepareAction<string>());

	removeTypingChat = this.createAction(EnumClientActions.REMOVE_TYPING_CHAT, this.getPrepareAction<string>());

	setIsGroupChatExpanded = this.createAction(
		EnumClientActions.SET_IS_GROUP_CHAT_EXPANDED,
		this.getPrepareAction<boolean>(),
	);
}

export type TForceFlagsActionParams = {
	chatID: string;
	flags: ChatFlags | string | undefined;
};

export type TBatchUpsertChatCommand = CMDC_UPDATE_CHAT['params'] | CMDC_CINIT['params'] | CMDC_CEXIT['params'];

export const chatsClientOnlyActions = new ChatsClientOnlyActions();
