import {call, select} from 'typed-redux-saga';
import _ from 'lodash';

import {EnumLocalStorageKeys} from '@messenger/core/src/BusinessLogic/EnumLocalStorageKeys';
import {DeviceTypes} from '@messenger/core/src/Redux/MediaDevice/slice';
import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import EnumStoreType from '@messenger/core/src/BusinessLogic/EnumStoreType';
import {selectAvailableMediaDevices} from '@messenger/core/src/Redux/MediaDevice/Selectors/selectAvailableMediaDevices';
import {selectChosenMediaDevices} from '@messenger/core/src/Redux/MediaDevice/Selectors/selectChosenMediaDevices';

export function* setChosenMediaDevicesToStoreSaga() {
	try {
		const chosenMediaDevices = yield* select(selectChosenMediaDevices);
		const allMediaDevices = yield* select(selectAvailableMediaDevices);
		const videoDevice = _.get(allMediaDevices, DeviceTypes.VIDEO_INPUT, []).find(
			(device: MediaDeviceInfo) => device.deviceId === chosenMediaDevices[DeviceTypes.VIDEO_INPUT],
		);
		const audioDevice = _.get(allMediaDevices, DeviceTypes.AUDIO_INPUT, []).find(
			(device: MediaDeviceInfo) => device.deviceId === chosenMediaDevices[DeviceTypes.AUDIO_INPUT],
		);

		const api = ServiceFactory.store;

		yield* call([api, api.set], EnumLocalStorageKeys.MEDIA_DEVICES, chosenMediaDevices, {
			storeType: EnumStoreType.LOCAL,
		});
		yield* call(
			[api, api.set],
			EnumLocalStorageKeys.MEDIA_DEVICES_INFO,
			{videoDevice, audioDevice},
			{storeType: EnumStoreType.LOCAL},
		);
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'setChosenMediaDevicesToStoreSaga'});
	}
}
