abstract class AbstractSoundMeter {
	abstract instant: number;

	abstract connectToSource(stream: MediaStream, callback: (error?: Error) => void): Promise<void>;
	protected abstract loadAudioWorkletModule(): Promise<void>;
	protected abstract connectWorkletToMic(): void;
	protected abstract connect(callback: (error?: Error) => void): Promise<void>;
	abstract stop(): void;
}

export default AbstractSoundMeter;
