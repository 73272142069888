import _ from 'lodash';

import EnumStore from '@messenger/core/src/BusinessLogic/EnumStore';
import IRootState from '@messenger/core/src/Redux/IRootState';
import {mediaLinkEntityAdapter, TMediaLink} from '@messenger/core/src/Redux/MediaLinks/mediaLinkEntityAdapter';
import {createSelector} from '@messenger/core/src/Utils/Redux';
import {getMediaLinkVmCached} from '@messenger/core/src/Redux/MediaLinks/getMediaLinkVmCached';
import {MediaLinkVm} from '@messenger/core/src/Redux/MediaLinks/MediaLinkVm';

const {
	selectAll: _selectAll,
	selectEntities: _selectEntities,
	selectById: _selectById,
} = mediaLinkEntityAdapter.getSelectors<IRootState>((state) => state[EnumStore.MEDIA_LINKS]);

export const selectMediaLinkVmById = createSelector([_selectById], (mediaLinkEntity) =>
	getMediaLinkVmCached(mediaLinkEntity),
);

export const selectMediaLinkVms = createSelector([_selectAll], (entities) =>
	_.chain(entities)
		.map((entity) => getMediaLinkVmCached(entity))
		.compact()
		.value(),
);

export const selectMediaLinksEntityVms = createSelector([_selectEntities], (entities) =>
	_.reduce(
		entities,
		(result: Record<string, MediaLinkVm>, entity: TMediaLink | undefined, id) => {
			const mediaLinkVm = getMediaLinkVmCached(entity);

			if (mediaLinkVm) {
				result[id] = mediaLinkVm;
			}

			return result;
		},
		{},
	),
);
