import {ChannelType} from 'cmd-control-client-lib';

import {createEntityAdapter} from '@messenger/core/src/Utils/Redux/createEntityAdapter';

export const sortByWeight = ({weight: channelWeightA = ''}, {weight: channelWeightB = ''}) => {
	if (channelWeightA > channelWeightB) {
		return -1;
	} else if (channelWeightA < channelWeightB) {
		return 1;
	}

	return 0;
};

export const channelAdapter = createEntityAdapter<ChannelType>({
	selectId: (channel: ChannelType) => channel.channelId,
	sortComparer: sortByWeight,
});
