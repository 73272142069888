import _ from 'lodash';

import {createSelector} from '@messenger/core/src/Utils/Redux';
import {selectRunningNonVoyeurChatIds} from '@messenger/core/src/Redux/Chats/Selectors/selectRunningNonVoyeurChatIds';
import selectActiveVoyeurChatsCount from '@messenger/core/src/Redux/Chats/Selectors/selectActiveVoyeurChatsCount';

export const selectActiveGuestsCount = createSelector(
	[selectRunningNonVoyeurChatIds, selectActiveVoyeurChatsCount],
	(chatIds, voyeurCount) => _.size(chatIds) + voyeurCount,
);
