import 'reflect-metadata';
import {container, registry} from 'tsyringe';

import DeviceMockApi from '@messenger/core/src/Services/Device/DeviceMockApi';
import AbstractSpeedTestApi from '@messenger/core/src/Services/SpeedTest/AbstractSpeedTestApi';
import VControlApi from '@messenger/core/src/Services/VControl/VControlApi';
import VControlFakerApi from '@messenger/core/src/Services/VControl/VControlFakerApi';
import IDeviceApi from '@messenger/core/src/Services/Device/IDeviceApi';
import AbstractWebRtcApi from '@messenger/core/src/Services/AbstractWebRtcApi';
import AbstractUiContainer from '@messenger/core/src/Services/AbstractUiContainer';
import AbstractJpegSocketPerChatPreviewApi from '@messenger/core/src/Services/JpegSocketPreviewApi/AbstractJpegSocketPerChatPreviewApi';
import AbstractI18n from '@messenger/core/src/Services/AbstractI18n';
import AbstractSelectorCreator from '@messenger/core/src/Services/AbstractSelectorCreator';
import AbstractStoreService from '@messenger/core/src/Services/AbstractStoreService';
import DIToken from '@messenger/core/src/BusinessLogic/DIToken';
import EnvInterfaceService from '@messenger/core/src/Services/EnvInterfaceService';
import AbstractUINotificationService from '@messenger/core/src/Services/AbstractUINotificationService';
import AbstractWebRtcPreviewChatApi from '@messenger/core/src/Services/WebRtcPreviewApi/AbstractWebRtcPreviewChatApi';
import AbstractVoiceMessageService from '@messenger/core/src/Services/AbstractVoiceMessageService';
import AbstractFeatureShowcaseService from '@messenger/core/src/Services/AbstractFeatureShowcaseService';
import AbstractFileProcessingService from '@messenger/core/src/Services/AbstractFileProcessingService';
import AbstractAnalyticsService from '@messenger/core/src/Services/AbstractAnalyticsService';
import {AbstractSipService} from '@messenger/core/src/Services/AbstractSipService';
import type ILogService from '@messenger/core/src/Services/ILogService';
import {TInfoWizard} from '@messenger/core/src/Redux/FeatureShowcase/TInfoWizard';
import AbstractBackgroundTaskService from '@messenger/core/src/Services/AbstractBackgroundTaskService';
import AbstractSoundMeter from '@messenger/core/src/Services/AbstractSoundMeter';

@registry([
	{
		token: DIToken.EnvInterfaceService,
		useToken: EnvInterfaceService,
	},
])
class ServiceFactory {
	static getVControlApi(serverDisabled = false): VControlApi | VControlFakerApi {
		return serverDisabled ? container.resolve(DIToken.VControlFakerApi) : container.resolve(DIToken.VControlApi);
	}

	static get speedTestApi(): AbstractSpeedTestApi {
		return container.resolve(DIToken.SpeedTestApi);
	}

	static get deviceApi(): IDeviceApi {
		return this.env.shouldEmulateDeviceApi() ? new DeviceMockApi() : container.resolve(DIToken.ToyControlApi);
	}

	static get webRtcApi(): AbstractWebRtcApi {
		return container.resolve(DIToken.WebRtcApi);
	}

	static get webRtcCam2camPreviewApi(): AbstractWebRtcPreviewChatApi {
		return container.resolve(DIToken.WebRtcCam2camPreviewApi);
	}

	static get uiContainer(): AbstractUiContainer {
		return container.resolve(DIToken.UiContainer);
	}

	static get jpegSocketPreviewApi(): AbstractJpegSocketPerChatPreviewApi {
		return container.resolve(DIToken.JpegSocketPerChatPreviewApi);
	}

	static get i18n(): AbstractI18n {
		return container.resolve(DIToken.I18n);
	}

	static get selectorCreator(): AbstractSelectorCreator {
		return container.resolve(DIToken.SelectorCreator);
	}

	static get store(): AbstractStoreService {
		return container.resolve(DIToken.StoreService);
	}

	static get env(): EnvInterfaceService {
		return container.resolve(DIToken.EnvInterfaceService);
	}

	static get notifications(): AbstractUINotificationService {
		return container.resolve(DIToken.UINotificationService);
	}

	static get voiceMessage(): AbstractVoiceMessageService {
		return container.resolve(DIToken.VoiceMessageService);
	}

	static get featureShowcase(): AbstractFeatureShowcaseService<TInfoWizard> {
		return container.resolve(DIToken.FeatureShowcase);
	}

	static get fileProcessing(): AbstractFileProcessingService {
		return container.resolve(DIToken.FileProcessingService);
	}

	static get analyticsService(): AbstractAnalyticsService {
		return container.resolve(DIToken.AnalyticsService);
	}

	static get logService(): ILogService {
		return container.resolve(DIToken.LogService);
	}

	static get soundMeter(): AbstractSoundMeter {
		return container.resolve(DIToken.SoundMeter);
	}

	static get sip(): AbstractSipService {
		return container.resolve(DIToken.Sip);
	}

	static get backgroundTaskService(): AbstractBackgroundTaskService {
		return container.resolve(DIToken.BackgroundTaskService);
	}
}

export default ServiceFactory;
