import _ from 'lodash';

import {THavingChatID, TSswMessageType} from '@messenger/core/src/Redux/Messages/Model';

const getMessageChatIds = (message: Partial<TSswMessageType> | THavingChatID) => {
	if (!!message.chatID && !_.isEmpty(message.chatID.trim())) {
		return message.chatID.split(',');
	}

	if (!!message.chatIDs && !_.isEmpty(message.chatIDs.trim())) {
		return message.chatIDs.split(',');
	}

	return [];
};

export default getMessageChatIds;
