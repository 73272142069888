import _ from 'lodash';
import {
	CMDP_CHANNELSSUMMARY_RESPONSE,
	EnumChannelFilterValues,
	channelIdFilter,
	channelSummaryInfo,
	getChannelsParamsType,
} from 'cmd-control-client-lib';

import {EnumChannelFilters} from '@messenger/core/src/Types/EnumChannelFilters';
import {initialState} from '@messenger/core/src/Redux/Channels/initialState';

const FILTERS_AND_FLAGS_DICT: Partial<Record<keyof CMDP_CHANNELSSUMMARY_RESPONSE['values'], keyof channelIdFilter>> = {
	archived: EnumChannelFilters.filterArchived,
	banned: EnumChannelFilters.filterBanned,
	muted: EnumChannelFilters.filterMuted,
	regular: EnumChannelFilters.filterRegularCustomer,
	unanswered: EnumChannelFilters.filterUnAnswered,
	unseen: EnumChannelFilters.filterUnseen,
	vip: EnumChannelFilters.filterVIP,
};

export const getFiltersToReset = (
	availableFilters: getChannelsParamsType & channelIdFilter,
	channelsSummary: channelSummaryInfo,
) => {
	return _.reduce(
		FILTERS_AND_FLAGS_DICT,
		(acc: channelIdFilter, filterName, channelTypeName) => {
			if (
				!_.isUndefined(filterName) &&
				_.parseInt(channelsSummary[channelTypeName], 10) === 0 &&
				availableFilters[filterName] === EnumChannelFilterValues.ONLY
			) {
				_.set(acc, filterName, initialState.filter[filterName]);
			}

			return acc;
		},
		{},
	);
};
