import {
	ACTION,
	ChannelMediaLinkState,
	CMDC_MEDIAPURCHASED,
	CMDP_SCHECKUPLOADEDMEDIA_RESPONSE,
	UploadedMediaDirection,
} from 'cmd-control-client-lib';

import {ServerToClientActions} from '@messenger/core/src/Actions/ActionCreator';
import EnumStore from '@messenger/core/src/BusinessLogic/EnumStore';

class MediaLinksServerToClientActions extends ServerToClientActions<EnumStore.MEDIA_LINKS> {
	readonly scope = EnumStore.MEDIA_LINKS;

	checkUploadedMediaReceived = this.createAction(
		ACTION.CMDP_SCHECKUPLOADEDMEDIA,
		this.getPrepareAction<CMDP_SCHECKUPLOADEDMEDIA_RESPONSE['values'], CMDP_SCHECKUPLOADEDMEDIA_RESPONSE, false>(),
	);

	mediaPurchased = this.createAction(
		ACTION.CMDC_MEDIAPURCHASED,
		(
			{mediaMd5, linkState, linkDate, currency, mediaPrice, channelId}: CMDC_MEDIAPURCHASED['params'],
			meta: CMDC_MEDIAPURCHASED,
		) => ({
			payload: {
				mediaMd5: String(mediaMd5),
				linkState: linkState || ChannelMediaLinkState.NONE,
				linkDate: String(linkDate),
				currency,
				mediaPrice,
				channelId: String(channelId),
				direction: UploadedMediaDirection.OUT,
			},
			meta,
		}),
	);
}

export const mediaLinksServerToClientActions = new MediaLinksServerToClientActions();
