import {container, inject, singleton} from 'tsyringe';
import _ from 'lodash';

import DIToken from '@messenger/core/src/BusinessLogic/DIToken';
import AbstractVoiceMessageService, {IRecordingStatus} from '@messenger/core/src/Services/AbstractVoiceMessageService';
import AbstractUINotificationService from '@messenger/core/src/Services/AbstractUINotificationService';
import AbstractI18n from '@messenger/core/src/Services/AbstractI18n';
import ILocalFile from '@messenger/core/src/Redux/Media/ILocalFile';
import AbstractUiContainer from '@messenger/core/src/Services/AbstractUiContainer';
import type ILogService from '@messenger/core/src/Services/ILogService';
import {EnumAbstractNotificationVariant} from '@messenger/core/src/Services/UINotification';
import AbstractSoundMeter from '@messenger/core/src/Services/AbstractSoundMeter';
import EnumSnackbarNotificationKeys from '@messenger/core/src/BusinessLogic/EnumSnackbarNotificationKeys';

@singleton()
class VoiceMessageService extends AbstractVoiceMessageService {
	constructor(
		@inject(DIToken.UINotificationService) protected notifications: AbstractUINotificationService,
		@inject(DIToken.I18n) protected i18n: AbstractI18n,
		@inject(DIToken.UiContainer) protected uiContainer: AbstractUiContainer,
		@inject(DIToken.LogService) protected logService: ILogService,
		@inject(DIToken.SoundMeter) protected soundMeter: AbstractSoundMeter,
	) {
		super();
	}

	private subscribers: Array<(status: IRecordingStatus) => void> = [];
	private recording?: MediaRecorder;
	private audioFile?: File;
	private chunks: Array<BlobPart> = [];
	private timeoutId: ReturnType<typeof setInterval> | undefined;
	private duration = 0;
	private isCancelled = false;

	start = async (selectedDevice?: string) => {
		const stream = await this.uiContainer
			.getWindow()
			.navigator.mediaDevices.getUserMedia({audio: selectedDevice ? {deviceId: selectedDevice} : true})
			.then((stream) => stream)
			.catch((e) => {
				this.notifications.enqueue({
					key: EnumSnackbarNotificationKeys.MICROPHONE_PERMISSION,
					text: this.i18n.t('audio:allow-mic-access'),
					variant: EnumAbstractNotificationVariant.WARNING,
				});

				return undefined;
			});

		if (_.isUndefined(stream)) {
			this.isCancelled = false;
			throw new Error('Failed to start audio recording');
		}

		if (this.isCancelled) {
			stream.getAudioTracks().forEach((track) => {
				track.stop();
			});
			this.isCancelled = false;

			return;
		}

		this.recording = new MediaRecorder(stream);

		this.recording.ondataavailable = (e) => {
			this.chunks.push(e.data);
		};

		if (this.timeoutId) {
			clearInterval(this.timeoutId);
		}

		this.recording.start();
		this.duration = 0;

		await this.soundMeter.connectToSource(this.recording.stream, (e?: Error) => {
			if (e) {
				this.logService.error(e, {service: 'VoiceMessageService'});

				return;
			}

			const interval = 100;
			const startTime = Date.now();

			this.timeoutId = setInterval(() => {
				this.duration = Date.now() - startTime;
				this.subscribers.forEach((callback) =>
					callback({
						durationMillis: this.duration,
						metering: _.get(this.soundMeter, 'instant'),
					}),
				);
			}, interval);
		});
	};

	cancelRecording = () => {
		this.isCancelled = true;
		this.clearRecording();
	};

	stop = (onStop: (file: File | ILocalFile | null, duration: number) => void) => {
		if (!this.recording) {
			return;
		}

		const duration = this.duration / 1000;
		const isValidRecording = duration > 1;

		this.recording.onstop = () => {
			if (isValidRecording) {
				this.audioFile = new File(this.chunks, 'voice-recording', {
					type: _.head(this.recording?.mimeType.split(';')) || 'audio/wav',
				});
				onStop(this.audioFile, duration);
			} else {
				onStop(null, duration);
			}

			this.chunks = [];
		};

		this.clearRecording();
	};

	clearRecording() {
		if (this.timeoutId) {
			clearInterval(this.timeoutId);
		}

		if (this.recording && this.recording.state !== 'inactive') {
			this.soundMeter.stop();
			this.recording.stop();
			this.recording.stream.getAudioTracks().forEach((track) => {
				track.stop();
			});
		}
	}

	addRecordingStatusUpdateCallback(callback: (status: IRecordingStatus) => void) {
		this.subscribers.push(callback);
	}

	removeRecordingStatusUpdateCallback(callback: (status: IRecordingStatus) => void) {
		_.remove(this.subscribers, (item) => item === callback);
	}
}

container.register(DIToken.VoiceMessageService, {useToken: VoiceMessageService});

export default VoiceMessageService;
