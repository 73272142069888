import {put, select} from 'typed-redux-saga';

import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {LIVE_SESSIONS_PAGE_SIZE} from '@messenger/core/src/BusinessLogic/Constants/Constants';
import {liveSessionsClientToServerActions} from '@messenger/core/src/Redux/LiveSessions/Actions/liveSessionsClientToServerActions';
import selectSkipLiveSessions from '@messenger/core/src/Redux/LiveSessions/Selectors/selectSkipLiveSessions';

export const loadLiveSessionsNextPageSaga = function* () {
	try {
		const skip = yield* select(selectSkipLiveSessions);

		yield* put(
			liveSessionsClientToServerActions.getLiveSessions({
				skip: String(skip),
				limit: String(LIVE_SESSIONS_PAGE_SIZE),
			}),
		);
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'loadLiveSessionsNextPageSaga'});
	}
};
