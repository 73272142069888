import {container, singleton} from 'tsyringe';
import {ACTION, UploadMediaTus, EnumFormatValues} from 'cmd-control-client-lib';
import axios from 'axios';

import {default as CoreVControlApi} from '@messenger/core/src/Services/VControl/VControlApi';
import ILocalFile from '@messenger/core/src/Redux/Media/ILocalFile';
import DIToken from '@messenger/core/src/BusinessLogic/DIToken';
import {TSendMediaParams} from '@messenger/core/src/Services/VControl/AbstractVControlApi';

@singleton()
class VControlApi extends CoreVControlApi {
	sendMedia(uploadUrl: string, file: File | ILocalFile, params: TSendMediaParams, tusConfig?: UploadMediaTus): void {
		if (!(file instanceof File)) {
			return;
		}

		if (tusConfig) {
			this.startTusUpload({file, params, tusConfig});
		} else {
			const {messageId, channelId} = params;
			const uploadParams = this.getMediaUploadQueryParams(params);
			const formData = new FormData();

			formData.append('file', file as File, file.name);

			if (channelId) {
				formData.append('channelId', channelId);
			}

			axios
				.request({
					url: uploadUrl,
					method: 'POST',
					params: {
						...uploadParams,
						format: EnumFormatValues.JSON,
						action: ACTION.CMDP_SUPLOADMEDIA,
					},
					data: formData,
					responseType: 'json',
					onUploadProgress: ({loaded, total}) => {
						this.onMediaUploadProgress({bytesUploaded: loaded, bytesTotal: total, messageId});
					},
				})
				.then((response) => {
					return this.dispatchResponse(response.data);
				})
				.catch((error) => {
					this.onMediaUploadError(error, messageId);
				});
		}
	}
}

container.register(DIToken.VControlApi, {useToken: VControlApi});

export default VControlApi;
