import {ChannelInfoType, CMDC_CHANNELINFO} from 'cmd-control-client-lib';

import {ClientOnlyActions, TMultiEntityActionMeta} from '@messenger/core/src/Actions/ActionCreator';
import EnumStore from '@messenger/core/src/BusinessLogic/EnumStore';

enum EnumClientActions {
	RESET_STORE = 'RESET_STORE',
	UPSERT_MANY = 'UPSERT_MANY',
	ADD_MANY = 'ADD_MANY',
	UPSERT_ONE = 'UPSERT_ONE',
	REMOVE_ONE = 'REMOVE_ONE',
	UPDATE_STORE = 'UPDATE_STORE',
}

class ChannelInfosClientOnlyActions extends ClientOnlyActions<EnumStore.CHANNEL_INFOS> {
	readonly scope = EnumStore.CHANNEL_INFOS;

	resetStore = this.createAction(EnumClientActions.RESET_STORE);

	upsertMany = this.createAction(
		EnumClientActions.UPSERT_MANY,
		this.getPrepareAction<ChannelInfoType[], TMultiEntityActionMeta<CMDC_CHANNELINFO>>(),
	);

	addMany = this.createAction(
		EnumClientActions.ADD_MANY,
		this.getPrepareAction<ChannelInfoType[], TMultiEntityActionMeta<CMDC_CHANNELINFO>>(),
	);

	upsertOne = this.createAction(EnumClientActions.UPSERT_ONE, this.getPrepareAction<ChannelInfoType>());

	removeOne = this.createAction(EnumClientActions.REMOVE_ONE, this.getPrepareAction<string>());

	updateStore = this.createAction(
		EnumClientActions.UPDATE_STORE,
		this.getPrepareAction<{isLoading: boolean; requestedIds: string[]}>(),
	);
}

export const channelInfosClientOnlyActions = new ChannelInfosClientOnlyActions();
