import {createSlice} from '@reduxjs/toolkit';
import _ from 'lodash';

import EnumStore from '@messenger/core/src/BusinessLogic/EnumStore';
import {
	mediaDeviceClientOnlyActions,
	mediaDeviceClientToServerActions,
} from '@messenger/core/src/Redux/MediaDevice/Actions';
import {EnumVideoFacingMode} from '@messenger/core/src/Types/media';
import {streamClientOnlyActions} from '@messenger/core/src/Redux/Stream/Actions/streamClientOnlyActions';

export enum DeviceTypes {
	VIDEO_INPUT = 'videoinput',
	AUDIO_INPUT = 'audioinput',
	AUDIO_OUTPUT = 'audiooutput',
}

export const initialAvailableMediaDevices: TAvailableMediaDevices = {
	[DeviceTypes.AUDIO_INPUT]: [],
	[DeviceTypes.VIDEO_INPUT]: [],
	[DeviceTypes.AUDIO_OUTPUT]: [],
};

export const initialChosenMediaDevices: TChosenMediaDevices = {
	[DeviceTypes.AUDIO_INPUT]: '',
	[DeviceTypes.VIDEO_INPUT]: '',
};

const initialState: TMediaDevice = {
	isMicDisabled: false,
	mediaDevicePermissionsRequested: false,
	hasActiveStream: false,
	cameraPermissionDenied: false,
	cameraError: undefined,
	micError: undefined,
	isMicPermissionDenied: false,
	chosenMediaDevices: initialChosenMediaDevices,
	availableMediaDevices: initialAvailableMediaDevices,
	browserSupportedVideoCodec: undefined,
	browserSupportedAudioCodec: undefined,
	supportedCodecs: undefined,
	isSupportedCodecsDetectionDone: false,
	chosenMediaDevicesInfo: undefined,
	shouldRestartStream: undefined,
	videoFacingMode: EnumVideoFacingMode.user,
};

const mediaDeviceSlice = createSlice({
	name: EnumStore.MEDIA_DEVICE,
	initialState: initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(mediaDeviceClientOnlyActions.requestMediaDevicePermissions, (state) => {
			state.mediaDevicePermissionsRequested = true;
			state.hasActiveStream = false;
			state.cameraPermissionDenied = false;
			state.isMicPermissionDenied = false;
			state.cameraError = undefined;
			state.micError = undefined;
		});
		builder.addCase(mediaDeviceClientOnlyActions.requestMediaDevicePermissionsReceived, (state, action) => {
			state.mediaDevicePermissionsRequested = false;
			state.hasActiveStream = true;
			state.isMicPermissionDenied = !action.payload.isMicAvailable;
			state.cameraPermissionDenied = false;
		});
		builder.addCase(mediaDeviceClientOnlyActions.requestMediaDevicePermissionsFailed, (state, action) => {
			state.mediaDevicePermissionsRequested = false;
			state.hasActiveStream = false;
			state.cameraPermissionDenied = true;
			state.cameraError = action.payload;
		});
		builder.addCase(mediaDeviceClientOnlyActions.requestMicPermissionFailed, (state, action) => {
			state.micError = action.payload;
			state.isMicPermissionDenied = true;
		});
		builder.addCase(mediaDeviceClientOnlyActions.releaseMediaDevicePermissions, (state) => {
			state.mediaDevicePermissionsRequested = false;
			state.hasActiveStream = false;
			state.cameraPermissionDenied = false;
		});
		builder.addCase(mediaDeviceClientOnlyActions.setChosenMediaDevices, (state, action) => {
			state.chosenMediaDevices = action.payload.mediaDevices;
		});
		builder.addCase(mediaDeviceClientOnlyActions.setChosenMediaDevicesFromStore, (state, action) => {
			state.chosenMediaDevices = action.payload;
		});
		builder.addCase(mediaDeviceClientOnlyActions.setChosenMediaDevicesInfoFromStore, (state, action) => {
			state.chosenMediaDevicesInfo = action.payload;
		});
		builder.addCase(mediaDeviceClientOnlyActions.setAvailableMediaDevices, (state, action) => {
			state.availableMediaDevices = action.payload;
		});
		builder.addCase(mediaDeviceClientOnlyActions.resetAvailableMediaDevices, (state) => {
			state.availableMediaDevices = initialAvailableMediaDevices;
		});
		builder.addCase(mediaDeviceClientOnlyActions.setIsMicDisabled, (state, action) => {
			state.isMicDisabled = action.payload;
		});
		builder.addCase(mediaDeviceClientOnlyActions.setSupportedCodecs, (state, action) => {
			state.supportedCodecs = action.payload;
			state.isSupportedCodecsDetectionDone = true;
		});
		builder.addCase(mediaDeviceClientOnlyActions.resetSupportedCodecs, (state) => {
			state.supportedCodecs = undefined;
			state.isSupportedCodecsDetectionDone = false;
		});
		builder.addCase(mediaDeviceClientOnlyActions.resetChosenMediaDevicesInfo, (state) => {
			state.chosenMediaDevicesInfo = undefined;
		});
		builder.addCase(mediaDeviceClientToServerActions.setMicStatus, (state, action) => {
			if (_.has(action.meta, 'isMicDisabled')) {
				state.isMicDisabled = action.meta.isMicDisabled;
			}
		});
		builder.addCase(mediaDeviceClientOnlyActions.setShouldRestartStream, (state, action) => {
			state.shouldRestartStream = action.payload;
		});
		builder.addCase(streamClientOnlyActions.setVideoFacingMode, (state, action) => {
			state.videoFacingMode = action.payload;
		});
	},
});

export type TChosenMediaDevices = {
	[DeviceTypes.VIDEO_INPUT]: string;
	[DeviceTypes.AUDIO_INPUT]: string;

	[index: string]: string;
};

export type TAvailableMediaDevices = {
	[DeviceTypes.AUDIO_INPUT]: MediaDeviceInfo[];
	[DeviceTypes.VIDEO_INPUT]: MediaDeviceInfo[];
	[DeviceTypes.AUDIO_OUTPUT]: MediaDeviceInfo[];

	[index: string]: MediaDeviceInfo[];
};

export type TMediaDevice = {
	isMicDisabled: boolean;
	mediaDevicePermissionsRequested: boolean;
	hasActiveStream: boolean;
	cameraPermissionDenied: boolean;
	isMicPermissionDenied: boolean;
	cameraError?: string;
	micError?: string;
	chosenMediaDevices: TChosenMediaDevices;
	availableMediaDevices: TAvailableMediaDevices;
	browserSupportedVideoCodec?: string;
	browserSupportedAudioCodec?: string;
	supportedCodecs?: TSupportedCodecs;
	isSupportedCodecsDetectionDone: boolean;
	chosenMediaDevicesInfo: TChosenMediaDevicesInfo | undefined;
	shouldRestartStream?: boolean;
	videoFacingMode: EnumVideoFacingMode;
};

export type TRequestMediaDevicePermissionsReceived = {
	isMicAvailable: boolean;
};

export type TSetChosenMediaDevicesPayload = {
	mediaDevices: TChosenMediaDevices;
	requestPermissions?: boolean;
};

export type TChosenMediaDevicesInfo = {
	videoDevice?: MediaDeviceInfo;
	audioDevice?: MediaDeviceInfo;
};

export type TRequestMediaDevicePermissionsPayload = {
	isMicrophoneRequired: boolean;
	ignoreVideoConstraints?: boolean;
	ignoreVideoFacingMode?: boolean;
};

export type TSupportedCodecs = {
	audio?: string;
	video?: string;
};

export default mediaDeviceSlice;
