import {createSelector} from '@messenger/core/src/Utils/Redux/createSelector';
import selectChannelsFilter from '@messenger/core/src/Redux/Channels/Selectors/selectChannelsFilter';
import selectGuestTypeFilter from '@messenger/core/src/Redux/Client/Selectors/GuestTypeFilter/selectGuestTypeFilter';
import {EnumGuestType} from '@messenger/core/src/Types/EnumGuestType';
import getIsFilterChanged from '@messenger/core/src/Redux/Channels/getIsFilterChanged';

const selectAreAllChannelsShown = createSelector(
	[selectChannelsFilter, selectGuestTypeFilter],
	(filter, guestFilterType) => {
		if (guestFilterType !== EnumGuestType.ALL) {
			return false;
		}

		return getIsFilterChanged(filter);
	},
);

export default selectAreAllChannelsShown;
