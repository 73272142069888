import {ChannelInfoType} from 'cmd-control-client-lib';
import _ from 'lodash';

import EnumStore from '@messenger/core/src/BusinessLogic/EnumStore';
import {channelInfoEntityAdapter} from '@messenger/core/src/Redux/ChannelInfos/channelInfoEntityAdapter';
import getChannelInfoVMCached from '@messenger/core/src/Redux/ChannelInfos/getChannelInfoVMCached';
import {TChannelEntityId} from '@messenger/core/src/Redux/Channels/Selectors/defaultSelectors';
import IRootState from '@messenger/core/src/Redux/IRootState';
import {TDefaultSelectors} from '@messenger/core/src/Redux/TDefaultSelectors';
import {createSelector} from '@messenger/core/src/Utils/Redux';

const {selectIds, selectEntities, selectById} = channelInfoEntityAdapter.getSelectors<IRootState>(
	(state) => state[EnumStore.CHANNEL_INFOS],
) as TDefaultSelectors<ChannelInfoType, TChannelEntityId>;

export const selectChannelInfoIds = selectIds;

export const selectChannelInfoEntityVMs = createSelector([selectEntities], (entities) =>
	_.mapValues(entities, (entity) => getChannelInfoVMCached(entity)),
);

export const selectChannelInfoVMById = createSelector([selectById], getChannelInfoVMCached);
