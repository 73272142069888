/**
 * @todo Should be reworked import of SoundMeterProcessor
 * @link https://campoint.atlassian.net/browse/VXLIV-1714
 */
import SoundMeterProcessor from 'worklet-loader?name=static/js/SoundMeterProcessor.[contenthash:8].worklet.js!src/Services/WebRtc/SoundMeterProcessor.worklet';
import {container, singleton} from 'tsyringe';

import DIToken from '@messenger/core/src/BusinessLogic/DIToken';
import AbstractSoundMeter from '@messenger/core/src/Services/AbstractSoundMeter';
import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';

@singleton()
class SoundMeter extends AbstractSoundMeter {
	public instant: number;
	private context: AudioContext;
	private mic?: MediaStreamAudioSourceNode;
	private worklet?: AudioWorkletNode;
	private loading: boolean;
	private loaded: boolean;

	constructor() {
		super();
		this.context = ServiceFactory.uiContainer.getAudioContext(false);
		this.instant = 0.0;
		this.loading = false;
		this.loaded = false;
	}

	async connectToSource(stream: MediaStream, callback: (error?: Error) => void) {
		try {
			await this.context.resume();
			!this.mic && (this.mic = this.context.createMediaStreamSource(stream));
			await this.connect(callback);
		} catch (e) {
			if (e instanceof Error) {
				callback(e);
			}

			throw e;
		}
	}

	protected async loadAudioWorkletModule(): Promise<void> {
		await fetch(SoundMeterProcessor, {cache: 'force-cache'})
			.then((response) => response.text())
			.then((text) => {
				const blob = new Blob([text], {type: 'application/javascript; charset=utf-8'});
				const objectUrl = URL.createObjectURL(blob);

				return this.context.audioWorklet.addModule(objectUrl).finally(() => {
					URL.revokeObjectURL(objectUrl);
				});
			});
	}

	protected connectWorkletToMic() {
		this.worklet = new AudioWorkletNode(this.context, 'sound-meter-processor');

		this.worklet.port.onmessage = (event) => {
			this.instant = event.data;
		};

		this.mic?.connect(this.worklet).connect(this.context.destination);
	}

	protected async connect(callback: (error?: Error) => void) {
		if (!this.loaded) {
			if (!this.loading) {
				this.loading = true;
				await this.loadAudioWorkletModule().then(() => {
					this.connectWorkletToMic();
					this.loaded = true;
					this.loading = false;
				});
			}
		} else {
			this.connectWorkletToMic();
		}

		callback();
	}

	stop() {
		this.context.suspend();

		if (this.mic) {
			this.mic.disconnect();
			this.mic = undefined;
		}

		if (this.worklet) {
			this.worklet.disconnect();
			this.worklet = undefined;
		}
	}
}

container.registerSingleton(DIToken.SoundMeter, SoundMeter);

export default SoundMeter;
