import _ from 'lodash';

import {chatSortComparer} from '@messenger/core/src/Redux/Chats/chatsSortComparer';
import IChat from '@messenger/core/src/Redux/Chats/Model';
import {createEntityAdapter} from '@messenger/core/src/Utils/Redux/createEntityAdapter';

export const chatAdapter = createEntityAdapter<IChat>({
	selectId: (chat: IChat) => _.toString(chat.chatID),
	sortComparer: chatSortComparer,
});
