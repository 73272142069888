import _ from 'lodash';
import {EnumCurrency, EnumMediaType} from 'cmd-control-client-lib';
import cuid from 'cuid';
import moment from 'moment/moment';

import {TAttachment} from '@messenger/core/src/Redux/Attachment/Model';
import getMediaType from '@messenger/core/src/Utils/Media/getMediaType';
import ILocalFile from '@messenger/core/src/Redux/Media/ILocalFile';
import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {TMediaPreviewInfo} from '@messenger/core/src/Types/media';
import {EnumMediaSource} from '@messenger/core/src/BusinessLogic/Media/EnumMediaSource';
import {MediaVm} from '@messenger/core/src/Redux/Media/MediaVm';

export const getAttachmentId = _.memoize((_?: string) => cuid());

const getLocalFileUrlMemoized = _.memoize(
	(file, _md5) => {
		return ServiceFactory.uiContainer.createObjectURL(file as File | ILocalFile);
	},
	(file, md5) => md5 || file?.uri || file,
);

export default class AttachmentVM<T extends File | ILocalFile = File | ILocalFile> {
	attachmentId: string;
	file?: T;
	mediaType?: EnumMediaType;
	price?: number;
	isPriceValid: boolean;
	isDurationValid: boolean;
	currency?: EnumCurrency;
	thumbnail?: string;
	videoUrl?: string;
	isTicketShow?: boolean;
	duplicateMediaVM?: MediaVm;
	mediaMd5?: string;
	imageSource?: string;
	audioSource?: string;
	duration?: number;
	filePath?: string;
	mediaSource?: EnumMediaSource;
	readonly isFree: boolean;
	readonly hasAttachmentError: boolean;
	readonly hasMedia: boolean;
	readonly isDuplicatedMedia: boolean;
	readonly isAudioMediaType: boolean;
	readonly mediaPreviewInfo: TMediaPreviewInfo;

	constructor(attachment: TAttachment<T>, mediaVm?: MediaVm) {
		this.attachmentId = attachment.attachmentId;
		this.file = attachment.file;
		this.price = attachment.price;
		this.isPriceValid = attachment?.isPriceValid ?? true;
		this.isDurationValid = attachment?.isDurationValid ?? true;
		this.currency = attachment.currency;
		const isUploadedMedia = !_.isEmpty(mediaVm);

		this.duplicateMediaVM = mediaVm;
		this.mediaMd5 = _.get(attachment, 'mediaMd5');
		this.thumbnail = attachment.thumbnail || this.duplicateMediaVM?.poster;
		this.isTicketShow = _.get(attachment, 'isTicketShow') || this.duplicateMediaVM?.isTicketMediaType;
		this.mediaSource = attachment.mediaSource;

		// getting media type
		if (this.isTicketShow) {
			this.mediaType = EnumMediaType.TICKET;
		} else {
			if (isUploadedMedia) {
				this.mediaType = this.duplicateMediaVM?.mediaType;
			} else if (this.file) {
				this.mediaType = getMediaType(this.file);
			} else {
				this.mediaType = attachment.mediaType;
			}
		}

		this.imageSource = this.getFileUrl(EnumMediaType.BITMAP);
		this.videoUrl = this.getFileUrl(EnumMediaType.VIDEO);
		this.audioSource = this.getFileUrl(EnumMediaType.AUDIO);

		this.filePath = this.imageSource || this.videoUrl || this.audioSource;

		// getting duration
		this.duration = !_.isUndefined(attachment.duration) ? attachment.duration : this.duplicateMediaVM?.duration;

		this.isFree = this.price === 0;
		this.hasAttachmentError = this.isTicketShow
			? !this.isPriceValid || !this.isDurationValid || _.isUndefined(this.price) || _.isUndefined(this.duration)
			: !this.isPriceValid || (_.isUndefined(this.file) && _.isEmpty(this.duplicateMediaVM));
		this.hasMedia = !_.isUndefined(this.file) || !_.isUndefined(this.duplicateMediaVM);
		this.isDuplicatedMedia = !_.isEmpty(this.duplicateMediaVM);
		this.isAudioMediaType = EnumMediaType.AUDIO === this.mediaType;
		this.mediaPreviewInfo = this.getMediaPreviewInfo();
	}

	getFileUrl(mediaType: EnumMediaType) {
		if (this.mediaType !== mediaType) {
			return;
		}

		return !_.isUndefined(this.file)
			? getLocalFileUrlMemoized(this.file, this.mediaMd5)
			: this.getDuplicateFileUrl(mediaType);
	}

	getDuplicateFileUrl(mediaType: EnumMediaType) {
		if (_.isUndefined(this.duplicateMediaVM)) {
			return;
		}

		switch (mediaType) {
			case EnumMediaType.BITMAP:
				return this.duplicateMediaVM.imgSrc;
			case EnumMediaType.VIDEO:
				return this.duplicateMediaVM.videoHls || this.duplicateMediaVM.videoFileUrl;
			case EnumMediaType.AUDIO:
				return this.duplicateMediaVM.audioMp3;
			default:
				return undefined;
		}
	}

	private getMediaPreviewInfo(): TMediaPreviewInfo {
		if (this.duplicateMediaVM && !this.duplicateMediaVM.hasTranscodingError) {
			return this.duplicateMediaVM.mediaPreviewInfo.type === EnumMediaType.AUDIO
				? {...this.duplicateMediaVM.mediaPreviewInfo, isColorful: true}
				: this.duplicateMediaVM.mediaPreviewInfo;
		}

		switch (this.mediaType) {
			case EnumMediaType.VIDEO:
				return {
					type: EnumMediaType.VIDEO,
					src: this.filePath,
					isTranscoded: false,
				};

			case EnumMediaType.AUDIO:
				return {
					type: EnumMediaType.AUDIO,
					src: this.filePath,
					duration: moment
						.duration(this.duration, 'seconds')
						.format(ServiceFactory.i18n.t('momentFormat:durationFormat.minutes'), {trim: false}),
					isColorful: true,
				};

			case EnumMediaType.TICKET:
				return {
					type: EnumMediaType.TICKET,
				};

			case EnumMediaType.BITMAP:
			default:
				return {
					type: EnumMediaType.BITMAP,
					src: this.filePath,
					file: this.file as File,
				};
		}
	}
}
