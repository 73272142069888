import {Grid} from '@mui/material';
import React from 'react';

const FormButtons = ({isAlertGroupButtons, ...restProps}: TFormButtonsProps) => (
	<Grid
		container
		alignItems="center"
		justifyContent="center"
		direction="row"
		gap={1}
		rowGap={3}
		sx={(theme) => ({
			margin: `${theme.spacing(isAlertGroupButtons ? 1.5 : 4)} 0`,
		})}
		{...restProps}
	/>
);

type TFormButtonsProps = {
	isAlertGroupButtons?: boolean;
	children?: React.ReactNode;
};
export default FormButtons;
