import _ from 'lodash';

import {PRICES_PRECISION} from '@messenger/core/src/BusinessLogic/Constants/Constants';

export const getNumberFormattingOptions = (
	value: number,
	precision = PRICES_PRECISION,
	isRoundedIfInteger = false,
): Partial<Intl.NumberFormatOptions> =>
	isRoundedIfInteger && (_.isUndefined(value) || Number.isInteger(value))
		? {minimumFractionDigits: 0}
		: {
				maximumFractionDigits: precision,
				minimumFractionDigits: precision || undefined,
		  };
