import {CMDP_SGETUPLOADEDMEDIA2_RESPONSE} from 'cmd-control-client-lib';

import {ClientOnlyActions} from '@messenger/core/src/Actions/ActionCreator';
import EnumStore from '@messenger/core/src/BusinessLogic/EnumStore';
import {TMediaLink} from '@messenger/core/src/Redux/MediaLinks/mediaLinkEntityAdapter';
import {TMediaHistoryFilters} from '@messenger/core/src/Redux/Media/mediaSlice';

enum MediaLinksAction {
	SET_MANY = 'SET_MANY',
	ADD_MANY = 'ADD_MANY',
	REQUEST_MEDIA_LINKS_HISTORY = 'REQUEST_MEDIA_LINKS_HISTORY',
	MEDIA_LINKS_HISTORY_RECEIVED = 'MEDIA_LINKS_HISTORY_RECEIVED',
}

class MediaLinksClientOnlyActions extends ClientOnlyActions<EnumStore.MEDIA_LINKS> {
	readonly scope = EnumStore.MEDIA_LINKS;

	setMany = this.createAction(MediaLinksAction.SET_MANY, this.getPrepareAction<TMediaLink[]>());

	addMany = this.createAction(MediaLinksAction.ADD_MANY, this.getPrepareAction<TMediaLink[]>());

	requestMediaLinksHistory = this.createAction(
		MediaLinksAction.REQUEST_MEDIA_LINKS_HISTORY,
		this.getPrepareAction<TMediaHistoryFilters>(),
	);

	mediaLinksHistoryReceived = this.createAction(
		MediaLinksAction.MEDIA_LINKS_HISTORY_RECEIVED,
		({
			payload,
			meta,
			error,
		}: {
			payload: CMDP_SGETUPLOADEDMEDIA2_RESPONSE['values'];
			meta: CMDP_SGETUPLOADEDMEDIA2_RESPONSE;
			error?: string | boolean;
		}) => ({
			payload,
			meta,
			error,
		}),
	);
}

export const mediaLinksClientOnlyActions = new MediaLinksClientOnlyActions();
