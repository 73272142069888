import {ticketShow} from 'cmd-control-client-lib';

import {createEntityAdapter} from '@messenger/core/src/Utils/Redux/createEntityAdapter';

export const ticketShowAdapter = createEntityAdapter<ticketShow>({
	selectId: (ts) => ts.showId,
	sortComparer: (ts1, ts2) => {
		return ts1.showStart < ts2.showStart ? 1 : -1;
	},
});
