export enum EnumLocalStorageKeys {
	REMEMBER_ME = 'rememberMe',
	PASSWORD_HASH = 'passwordHash',
	LOGIN = 'login',
	INTRO_SHOWN = 'introShown',
	MEDIA_DEVICES = 'mediaDevices',
	MEDIA_DEVICES_INFO = 'mediaDevicesInfo',
	LAST_SUCCESSFUL_STREAM_TYPE = 'lastSuccessfulStreamType',
	I_18_NEXT_LNG = 'i18nextLng',
	CHAT_FONT_SIZE = 'chatFontSize',
	TOS_ACCEPTED = 'tosAccepted',
	CLIENT_ONLY_CHANNELS_META = 'clientOnlyChannelsMeta',
	VIBRATE_MAX_INTENSITY = 'vibrateMaxIntensity',
	FEATURE_SHOWN = 'featureShown',
	FEEDBACK_DATA = 'feedbackData',
	SHOW_MODAL_AFTER_TICKET_SHOW = 'showModalAfterTicketShow',
	// used for mobile only
	RECENT_EMOJI = 'recentEmoji',
	INCOMING_VIDEO_CALL = 'incomingVideoCall',
	TEMP_MESSAGES = 'tempmsg',
	LAST_SKIPPED_VERSION = 'lastSkippedVersion',
	TUS_UPLOAD_PREFIX = 'tus::',
}
