import React, {useEffect} from 'react';
import classNames from 'classnames';
import _ from 'lodash';

import {EDITOR_MAX_LENGTH} from '@messenger/core/src/BusinessLogic/Constants/Constants';
import EnumChatPageTestIds from '@messenger/uikit/src/TestIds/EnumChatPageTestIds';
import PropertyHandler from '@messenger/core/src/Types/PropertyHandler';
import type {TMessageInputEntity} from '@messenger/core/src/Redux/MessageInput/TMessageInputEntity';
import {replaceLegacySmileys} from '@messenger/core/src/BusinessLogic/replaceLegacySmileys';
import {getObjectStoreServiceRef} from '@messenger/core/src/Services/ObjectStore/getObjectStoreServiceRef';
import {ObjectStoreService} from '@messenger/core/src/Services/ObjectStore/ObjectStoreService';

import style from './TextInput.module.scss';

export const MESSAGE_INPUT_REF_ID = 'MESSAGE_INPUT_REF_ID';

const TextInput = ({isDisabled, value, onEnterKeyDown, placeholder, onValueChange, onTyping}: TTextInputProps) => {
	const messageInputId = value.id;

	useEffect(() => {
		const textAreaEl = ObjectStoreService.get<HTMLTextAreaElement>(MESSAGE_INPUT_REF_ID);

		if (!textAreaEl || textAreaEl.disabled) {
			return;
		}

		textAreaEl.focus();
		const caretPosition = textAreaEl.value.length;

		if (caretPosition > 0) {
			textAreaEl.setSelectionRange(caretPosition, caretPosition);
		}

		return () => {
			onValueChange({id: messageInputId, caretPosition: undefined});
		};
	}, [messageInputId, onValueChange]);

	return (
		<textarea
			wrap="hard"
			spellCheck={true}
			disabled={isDisabled}
			maxLength={EDITOR_MAX_LENGTH}
			ref={getObjectStoreServiceRef(MESSAGE_INPUT_REF_ID)}
			onKeyDown={(event) => {
				if (event.key === 'Enter') {
					event.preventDefault();

					onEnterKeyDown();
				}
			}}
			onChange={(event) => {
				let newValue = event.target.value;

				if (_.size(newValue) >= EDITOR_MAX_LENGTH) {
					newValue = _.truncate(newValue, {length: EDITOR_MAX_LENGTH, omission: ''});
				}

				newValue = replaceLegacySmileys(newValue);

				onValueChange({
					id: value.id,
					value: newValue,
				});

				onTyping();
			}}
			value={isDisabled || !value?.value ? '' : value?.value}
			placeholder={placeholder}
			data-test-id={EnumChatPageTestIds.MESSAGE_INPUT_CONTENT}
			className={classNames(style.input, {
				[style.empty]: !value?.value,
			})}
		/>
	);
};

type TTextInputProps = {
	isDisabled: boolean;
	placeholder?: string;
	value: TMessageInputEntity;
	onValueChange: PropertyHandler<TMessageInputEntity>;
	onEnterKeyDown: PropertyHandler;
	onTyping: PropertyHandler;
};

export default TextInput;
