import {put, select} from 'typed-redux-saga';

import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {mediaClientOnlyActions} from '@messenger/core/src/Redux/Media/Actions/mediaClientOnlyActions';
import {mediaClientToServerActions} from '@messenger/core/src/Redux/Media/Actions/mediaClientToServerActions';
import {mediaLinksClientOnlyActions} from '@messenger/core/src/Redux/MediaLinks/Actions/mediaLinksClientOnlyActions';
import {selectMediaLinksHistoryStatus} from '@messenger/core/src/Redux/MediaLinks/Selectors/MediaLinksHistory/selectMediaLinksHistoryStatus';
import {selectMediaHistoryStatus} from '@messenger/core/src/Redux/Media/Selectors/MediaHistory/selectMediaHistoryStatus';
import {mediaLinksClientToServerActions} from '@messenger/core/src/Redux/MediaLinks/Actions/mediaLinksClientToServerActions';

export function* requestMediaHistorySaga({
	type,
	payload: filters,
}: ReturnType<
	typeof mediaClientOnlyActions.requestMediaHistory | typeof mediaLinksClientOnlyActions.requestMediaLinksHistory
>) {
	try {
		const isMediaLinksHistory = type === mediaLinksClientOnlyActions.requestMediaLinksHistory.type;
		const mediaHistoryStatus = yield* select(
			isMediaLinksHistory ? selectMediaLinksHistoryStatus : selectMediaHistoryStatus,
			filters,
		);

		if (!mediaHistoryStatus?.isLoading && !mediaHistoryStatus?.endReached) {
			if (isMediaLinksHistory) {
				yield* put(
					mediaLinksClientToServerActions.requestMediaLinksHistory({
						skip: mediaHistoryStatus?.skip,
						...filters,
					}),
				);
			} else {
				yield* put(
					mediaClientToServerActions.requestMediaHistory({
						skip: mediaHistoryStatus?.skip,
						...filters,
					}),
				);
			}
		}
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'requestMediaHistorySaga'});
	}
}
