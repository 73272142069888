import {ACTION, ChannelMediaLinkState} from 'cmd-control-client-lib';
import _ from 'lodash';

import {ClientToServerActions} from '@messenger/core/src/Actions/ActionCreator';
import EnumStore from '@messenger/core/src/BusinessLogic/EnumStore';
import {getMediaHistorySubjectId, TMediaHistoryFilters} from '@messenger/core/src/Redux/Media/mediaSlice';

export const MEDIA_LINKS_HISTORY_SUBJECT_ID = 'mediaLinksHistorySubjectId';

class MediaLinksClientToServerActions extends ClientToServerActions<EnumStore.MEDIA_LINKS> {
	readonly scope = EnumStore.MEDIA_LINKS;

	checkUploadedMedia = this.createAction(
		ACTION.CMDP_SCHECKUPLOADEDMEDIA,
		({targetChannelIds, mediaMd5}: {targetChannelIds: string[]; mediaMd5?: string}) => ({
			payload: {
				mediaMd5,
				channelId: targetChannelIds.join(','),
			},
		}),
	);

	requestMediaLinksHistory = this.createAction(
		ACTION.CMDP_SGETUPLOADEDMEDIA2,
		({
			limit,
			skip,
			...filters
		}: TMediaHistoryFilters & {
			limit?: number;
			skip?: number;
		}) => ({
			payload: {
				[MEDIA_LINKS_HISTORY_SUBJECT_ID]: getMediaHistorySubjectId(filters),
				channelId: filters.channelId || '',
				linkState: _.join(filters.linkState, ','),
				mediaType: _.join(filters.mediaType, ','),
				direction: filters.direction || '',
				limit: String(limit || 50),
				skip: String(skip || 0),
				sortBy: _.first(filters.linkState) === ChannelMediaLinkState.NONE ? 'mediaCreated' : 'linkDate',
			},
		}),
	);
}

export const mediaLinksClientToServerActions = new MediaLinksClientToServerActions();
