import _ from 'lodash';
import {channelIdFilter, getChannelsParamsType} from 'cmd-control-client-lib';

import {initialState} from '@messenger/core/src/Redux/Channels/initialState';

export type TIgnoredFilterFields =
	| Array<keyof (getChannelsParamsType & channelIdFilter)>
	| keyof (getChannelsParamsType & channelIdFilter);

const getIsFilterChanged = (
	filter: getChannelsParamsType & channelIdFilter,
	ignoredFields?: TIgnoredFilterFields,
): boolean => {
	const defaultIgnoredFields: TIgnoredFilterFields = ['limit', 'skip'];

	if (ignoredFields) {
		if (_.isArray(ignoredFields)) {
			defaultIgnoredFields.push(...ignoredFields);
		} else {
			defaultIgnoredFields.push(ignoredFields);
		}
	}

	return _.isEqual(_.omit(filter, defaultIgnoredFields), _.omit(initialState.filter, defaultIgnoredFields));
};

export default getIsFilterChanged;
