export enum EnumChannelFilters {
	filterArchived = 'filterArchived',
	filterBanned = 'filterBanned',
	filterMuted = 'filterMuted',
	filterPages = 'filterPages',
	filterPartners = 'filterPartners',
	filterRegularCustomer = 'filterRegularCustomer',
	filterAdvertised = 'filterAdvertised',
	filterUnAnswered = 'filterUnAnswered',
	filterUnseen = 'filterUnseen',
	filterVIP = 'filterVIP',
}
