import {createEntityAdapter} from '@messenger/core/src/Utils/Redux/createEntityAdapter';
import {IDeviceToy} from '@messenger/core/src/Redux/Toy/IDeviceToy';

export const toyAdapter = createEntityAdapter<IDeviceToy>({
	/** selectId - should be unique and always existing field for every entity **/
	selectId: (toy) => toy.id,
	/** sortComparer - optional. Could be skipped for most of reducers  **/
	sortComparer: (toy1, toy2) => {
		const enabled1 = toy1.status || 0;
		const enabled2 = toy2.status || 0;

		if (enabled1 !== enabled2) {
			return enabled1 > enabled2 ? -1 : 1;
		}

		return 0;
	},
});
