import {put, select} from 'typed-redux-saga';
import {PayloadAction} from '@reduxjs/toolkit';
import _ from 'lodash';

import {TSetChosenMediaDevicesPayload} from '@messenger/core/src/Redux/MediaDevice/slice';
import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {selectAvailableMediaDevices} from '@messenger/core/src/Redux/MediaDevice/Selectors/selectAvailableMediaDevices';
import {mediaDeviceClientOnlyActions} from '@messenger/core/src/Redux/MediaDevice/Actions';

function* setChosenMediaDevicesSaga(action: PayloadAction<TSetChosenMediaDevicesPayload>) {
	try {
		if (!_.isEmpty(action.payload.mediaDevices.videoinput) || !_.isEmpty(action.payload.mediaDevices.audioinput)) {
			const allMediaDevices = yield* select(selectAvailableMediaDevices);
			const videoDevice = _.get(allMediaDevices, 'videoinput', []).find(
				(device: MediaDeviceInfo) => device.deviceId === action.payload.mediaDevices.videoinput,
			);
			const audioDevice = _.get(allMediaDevices, 'audioinput', []).find(
				(device: MediaDeviceInfo) => device.deviceId === action.payload.mediaDevices.audioinput,
			);

			yield* put(mediaDeviceClientOnlyActions.setChosenMediaDevicesInfoFromStore({videoDevice, audioDevice}));
		}

		if (action.payload.requestPermissions) {
			yield* put(mediaDeviceClientOnlyActions.releaseMediaDevicePermissions());
			yield* put(
				mediaDeviceClientOnlyActions.requestMediaDevicePermissions({
					isMicrophoneRequired: true,
				}),
			);
		}
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'saveChosenMediaDevicesToStore2Saga'});
	}
}

export default setChosenMediaDevicesSaga;
