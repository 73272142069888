import {SupportedLanguage} from 'cmd-control-client-lib';
import {TFunction} from 'react-i18next';

export enum EnumProfileFieldDataType {
	STRING = 'string',
	RANGE = 'array',
	DATE = 'date',
}

export type TProfileFiledValue = {
	dataType: 'range' | 'string';
	minValue: number;
	maxValue: number;
	minLabel: string;
	maxLabel: string;
	value: number;
};

export enum EnumChatTimes {
	firstChat = 'firstChat',
	lastChat = 'lastChat',
	totalChats = 'totalChats',
}

export type TProfileFields = {
	dataType: EnumProfileFieldDataType;
	key: EnumChatTimes;
	value: TProfileFiledValue[] | string;
};

export const getChatTimeLabelTranslated = (key: EnumChatTimes, t: TFunction) => {
	switch (key) {
		case EnumChatTimes.firstChat:
			return t('user:firstchat');

		case EnumChatTimes.lastChat:
			return t('user:lastchat');

		case EnumChatTimes.totalChats:
			return t('user:numchats');

		default:
			return key;
	}
};

export type TChannelInfoPayload = {
	[key in SupportedLanguage]: TProfileFields[];
};
