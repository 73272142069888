import EnumStoreType from '@messenger/core/src/BusinessLogic/EnumStoreType';

export interface IStoreOptions {
	storeType: EnumStoreType;
}

abstract class AbstractStoreService {
	abstract get(key: string, options: IStoreOptions): Promise<any>;
	abstract set(key: string, value: any, options?: IStoreOptions): Promise<any>;
	abstract remove(key: string, options?: IStoreOptions): Promise<any>;
	abstract getAllKeys(): Promise<readonly string[]>;
	abstract multiGet(keys: string[]): Promise<readonly [string, string | null][]>;
	abstract multiRemove(keys: readonly string[]): Promise<void>;
}

export default AbstractStoreService;
