/* eslint-disable no-restricted-imports */
import {
	Comparer,
	createEntityAdapter as createEntityAdapterToolkit,
	EntityState,
	IdSelector,
	PayloadAction,
} from '@reduxjs/toolkit';
import _ from 'lodash';

//patch upsertMany to fix an issue: https://github.com/reduxjs/redux-toolkit/issues/3429
export const createEntityAdapter = <T>(
	options: {
		selectId?: IdSelector<T>;
		sortComparer?: false | Comparer<T>;
	} = {},
) => {
	const adapter = createEntityAdapterToolkit(options);
	const {upsertMany: upsertManyToolkit, selectId} = adapter;

	return {
		...adapter,
		upsertMany: <S extends EntityState<T>>(state: S, entities: PayloadAction<readonly T[]>): S => {
			if (entities.payload) {
				return upsertManyToolkit(state, {
					...entities,
					payload: _.chain(entities.payload)
						.groupBy(selectId)
						.values()
						.map((grouped) => (_.size(grouped) === 1 ? _.first(grouped) : _.assign({}, ...grouped)))
						.value(),
				});
			}

			return upsertManyToolkit(state, entities);
		},
	};
};
